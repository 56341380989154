<template>

  <mainPage></mainPage>
  
</template>

<script>
import mainPage from './components/main.vue'

export default {
  name: 'App',
  components: {
    mainPage
  }
}
</script>

<style>
body{
  background-color:azure;
}
#app {
  font-family: marine, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
/*  margin-top: 60px;*/
}
.Light{
font-weight: 300;
font-style: normal;
}
.Light-Italic{
font-weight: 300;
font-style: italic;
}
.Regular{
font-weight: 400;
font-style: normal;
}
.Italic{
font-weight: 400;
font-style: italic;
}
.Bold{
font-weight: 700;
font-style: normal;
}
.Bold-Italic{
font-weight: 700;
font-style: italic;
}
.Black{
font-weight: 900;
font-style: normal;
}
.Black-Italic{
font-weight: 900;
font-style: italic;
}
</style>
