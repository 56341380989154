

<template>
        <v-container class="w-75">
          <v-row no-gutters>
            <v-col  align="left" xs="12" sm="12" md="6" lg="6" xl="6">
              <!--<div>Icono</div>-->
            </v-col>
            <v-col xs="12" sm="12" md="2" lg="2" xl="2" v-for="item in temasDatos" :key="item.ID">
             <div>
                {{item.titulo}}
             </div>
            </v-col>          
          </v-row>
          <v-row>
            <v-col>
                                                                                                                               
            </v-col>
            
          </v-row>
          <v-row v-for="item in temasDatos" :key="item.ID" >
            <v-col lg="4" align="center" justify="center">
              <v-img src="https://neliel.mx/img/logo_neliel.jpg" width="150"></v-img>  
            </v-col>
            <v-col lg="8" class="text-center text-md-center" align="center" justify="center">
              <div>
                ¿Qué es Lorem Ipsum?
                Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.
              </div>
            </v-col>  
            <v-col></v-col>   
          </v-row>
        </v-container>
        
        <v-container class="w-50">
          <v-row justify-center align-center>
            <v-col class="w-75">
              <img src="https://neliel.mx/img/logo_neliel.png" width="250"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
                <div>
                  <h3>Proximamente...</h3>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <hr />
              </v-col>
            </v-row>
          <v-row>
            <v-col>
              <div class="text-h4">Contáctanos</div>    
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <a  target="_blank" style="text-decoration: none;" href="https://www.google.com/maps/place/Neliel+Papeleria+y+Novedades/@20.6876996,-105.2224124,17z/data=!4m14!1m7!3m6!1s0x84214536a59a2f7f:0x4e6a201dc44ba215!2sNeliel+Papeleria+y+Novedades!8m2!3d20.6876996!4d-105.2198375!16s%2Fg%2F11nyl8tdkc!3m5!1s0x84214536a59a2f7f:0x4e6a201dc44ba215!8m2!3d20.6876996!4d-105.2198375!16s%2Fg%2F11nyl8tdkc?entry=ttu">
              <span class="material-symbols-outlined">
                location_on
              </span>
               <div>
                   Oaxaca #147, Col. Las Mojoneras,
                   Puerto Vallarta, Jalisco
               </div>
              </a>
            </v-col>
            <v-col>
              <a href="mailto:contacto@neliel.mx" style="text-decoration: none;">
                <span class="material-symbols-outlined">email</span>
                <div>
                  contacto@neliel.mx
                </div>
              </a>
            </v-col>
            <v-col justify-center align-center>           
              <a href="https://wa.me/3221472806" target="_blank" style="text-decoration: none;">
                  <div>
                    <img src="https://neliel.mx/img/whatsapp.png" width="25"/>
                    <p>322 147 2806</p>                    
                  </div>
                </a>
            </v-col>
          </v-row>
        </v-container>
      <div id="footer">
        <!--<footerSection></footerSection>-->
      </div>

   <!--   -->
</template>

<script>
//import footerSection from './footer.vue'

export default {
    name:'mainPage', 
    components:{
       // headerSection,
       // footerSection
    },
    data: () => {
      return {
        temasDatos:[/*
          {"ID":"1",
          "titulo":"Nuestros Servicios",
          "seccion":"Servicios",
           "icon":"https://neliel.mx/img/servicios.png",
          "descripcion":" Impresiones, Emicados, Engargolados"
          },
          {"ID":"2",
          "titulo":"Agencia de Paquetes",
          "seccion":"ML",
          "icon":"https://neliel.mx/img/agencia.png",
          "descripcion":"Somos Agencia de Paquetes de Mercaro libre" 
          },

          {"ID":"3",
           "titulo":"Papeleria",
           "seccion":"Papeleria",
           "icon":"https://neliel.mx/img/papeleriaItem.png",
          "descripcion":"Contamos con articulos de papeleria y novedades"
          }*/
        ]
      }
    },
    methods:{
      getdata:() => {
       console.log('main')
      }
    },
    mounted() {
      let self  = this;
      self.getdata();
    }

}
</script>


<style>
  #footer{
    background-color: #003b4c;
    color: white;
  }
a:link {
  color: black;
}

/* visited link */
a:visited {
  color:black;
}

/* mouse over link */
a:hover {
  color: black;
}

/* selected link */
a:active {
  color: black;
}
</style>